// ------------------------------------------
// ------------- VARIABLES ------------------
// ------------------------------------------

// Usar map-get($colors, verde-claro);
$colors: (
  amarillo:          #ffd600,
  amarillo-claro:    #f1e2a6,
  verde-ilusion:     #007B22,
  verde-claro:       #eff7f2,
  gris-oscuro-20:    #202020,
  gris-oscuro-30:    #303030,
  gris-oscuro-40:    #404040,
  gris-claro:        #CCC,
  gris-ddd:          #DDD,
  gris-muy-claro:    #EEE,
  privado:           #A40000,
);

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;
          box-shadow: $shadow;
}

@mixin link-haf () {
     &:hover,
     &:active,
     &:focus { @content }
}

// Aniadir position relative a la etiqueta que lo use
// Usar de la siguiente forma personalizando entre otras cosas el top y el right
// &:after {
//        @extend %arrow_bottom;
//        top:0.45em;
//        right: 0;
//      }

%arrow_bottom {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-top-color: black;
    border-width: 0.5em;

    .altocontraste & {border-top-color: white;}
}

%arrow_top {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: black;
    border-width: 0.5em;

    .altocontraste & {border-bottom-color: white;}
}

// Aniadir position relative a la etiqueta que lo use
// Usar de la siguiente forma
//   &:after {
//     @extend %arrow_bottom;
//   }
//   &:before {
//     transform: rotate(45deg);
//   }
//   &:after {
//     transform: rotate(-45deg);
//   }

%close {
  position: absolute;
  left: 0.45em;
  content: ' ';
  height: 1em;
  width: 2px;
  background-color: #333;

  .altocontraste & {background-color:white;}
}


// Implementamos un nuevo hiddenStructure
%element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}
%element-oculto {display:none;visibility:hidden;}

