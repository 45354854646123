// --------- ATENCION: ---------------------
// NO USAR tildes ni caracteres extranos en los comentarios
// Esto aniade una linea de chartset al inicio de la css compilada que
// genera un error

// ------------------------------------------
// ----------- importaciones ----------------
// ------------------------------------------

@import 'variables';


/* ----------------- Estilos para la BDO ------------------------ */

.bibliotecaBDO,
#content .bibliotecaBDO {

    .documentDescription {
        margin-bottom: 1em;
    }

    .filtroSistema {
        display: inline-block;
        margin-right: 1em;
    }

    .filtroValoracion {
        display: inline-block;
    }

    .filtroBusquedaExperta {
        // display: block;
        // margin-bottom: 1em;

        select,
        input {
            margin-right: 0.5em;
            margin-bottom: 0.5em;
            width: 100%;
        }

        :nth-child(2),
        :nth-child(6) {
            max-width: 12em;
        }

        :nth-child(4) {
            max-width: 15em;
        }

        :nth-child(8) {
            max-width: 7em;
        }
    }

    // Campos especificos
    #obraAd,
    #anioAd {
        max-width: 6em;
    }

    // Utilidades BDO
    .utilidades-bdo {
        margin: 0;

        li {
            display: inline-block;
            padding: 0;
            background: none;
            margin: 0;

            &.loHeLeido {
                margin: 0 1em;
            }

            &.loHeLeidoSpan {
                display: block;
                margin: 0 0 1em 0;
            }

            &.aniadirComentario {
                margin-top: 1em;
                display: block;
                clear: both;
            }
        }
    }

    // Botones Quiero leer
    .quieroLeer a,
    a.yaNoQuieroLeer {
        cursor: pointer;

    }


    a.yaNoQuieroLeer {

        @include link-haf {
            background-color: white;
            color: map-get($colors, verde-ilusion);
        }

    }

    a.yaNoQuieroLeer:hover {

        .plone-icon {
            background-color: white;
            color: map-get($colors, verde-ilusion);
        }

    }


    .gestionComentarios {
        background-color: map-get($colors, verde-claro);
        margin-top: 1.5em;
        padding: 1em;

        .altocontraste & {
            background-color: map-get($colors, gris-oscuro-30);
        }

        .field>span {
            font-weight: bold;
            margin-right: 1em;
            margin-top: 0.5em;
            display: inline-block;
        }

        .field>span.escritoSpan {
            margin-left: 0;
        }

        em {
            font-weight: normal;
        }

        .comentarioRechazo {
            display: block;
        }
    }
}

// Administracion de obras no protegidas por derechos de autor

#content .op6form.admin-obras-no-protegidas textarea {
    max-width: 10em;
    min-height: 12em;
}

#content .obrasautor-bdo__numero-resultados> :first-child {
    padding: 0 !important;
}

.DeleteRating {
    padding: 0.1em !important;
    border-radius: 0.25em !important;
    line-height: 1 !important;

    svg {margin: 0; display: inline-block;}
}


// Estilos heredados del antiguo CLUBONCE

div.bibliotecaBDO .documentActions li {
    margin: 0;
    font-weight: normal;
}

div.bibliotecaBDO #fieldset-1 ul li {
    font-weight: normal;
    line-height: 1.6em !important;
}

div.bibliotecaBDO #fieldset-3 ul li {
    font-weight: normal;
    line-height: 1.6em !important;
}

div.bibliotecaBDO #fieldset-1 div.field input[type="checkbox"],
div.bibliotecaBDO #fieldset-2 div.field input[type="checkbox"],
div.bibliotecaBDO #fieldset-3 div.field input[type="checkbox"] {
    margin-right: 0.5em;
    vertical-align: middle;
}

div.bibliotecaBDO #fieldset-1 div.field label,
div.bibliotecaBDO #fieldset-2 div.field label,
div.bibliotecaBDO #fieldset-3 div.field label {
    vertical-align: middle;
}

#help_label_lector {
    margin-right: 3.1em !important;
    font-weight: normal;
}

#help_label_duracion {
    margin-right: 1.7em !important;
    font-weight: normal;
}

#help_label_volumenes {
    margin-right: 2.1em;
    font-weight: normal;
}

#autor_label {
    margin-right: 0.6em !important;
}



#content div.bibliotecaBDO li.li_label_loQuieroLeer {
    min-width: 30%;
}

#content div.bibliotecaBDO div.label_loQuieroLeer {
    display: inline-block;
    padding: 0.5em 0 0.5em 0;
    margin-right: 1em;
}

#content div.bibliotecaBDO div.label_loQuieroLeer span {
    background-color: #eff7f2;
    color: black;
    display: inline-block;
    font-weight: bold;
    padding: 0 1.2em;
    line-height: 1.65em;
}



#content div.bibliotecaBDO li.ObservacionLectura {
    clear: both;
    display: block;
}

#content div.bibliotecaBDO li.ObservacionLectura p {
    margin-top: 0;
}

#content div.bibliotecaBDO span.VoteCount {
    margin-left: 0.5em;
    margin-right: 0.5em;
}

div.criteriosBusqOrden {
    display: table;
    width: 100%;
}

#content div.bibliotecaBDO dd.sistemaUno {
    display: inline;
    float: left;
    margin-right: 1em;
}

.eliminarBusqueda {
    background: url("++resource++afiliados.bdo.resources/eliminar_busqueda.png") no-repeat top left;
    padding: 0 0 1em 2em;
}

#content div.bibliotecaBDO.estadisticaBDO .fieldInlineBlock {
    display: inline-block;
    margin-right: 1em;
}

#content div.bibliotecaBDO.estadisticaBDO .fieldInlineBlock>.fieldInlineBlock {
    display: inline-block;
    margin-right: 0;
}

div#informacionDescargas {
    margin-bottom: 0em;
}

.infoDSY {
    margin-bottom: 1em;
    font-weight: bold;
}

.infoDSY span {
    margin-right: 1.5em;
}

.infoDSY em {
    font-weight: normal;
}

.infoBRA {
    margin-bottom: 1em;
    font-weight: bold;
}

.infoBRA em {
    font-weight: normal;
}

div.bibliotecaBDO #content-core .comentarioBDO textarea {
    display: block;
}

div.bibliotecaBDO span.mejorValoradosSpan {
    margin-left: 0.5em;
}


/* Estilos para Alto Contraste */

.altocontraste #content div.bibliotecaBDO div.label_loQuieroLeer span {
    background-color: #303030;
    border-color: white;
    color: white !important;
}

.altocontraste #content div.bibliotecaBDO div.label_loQuieroLeer {
    background: transparent url("++resource++afiliados.bdo.resources/altocontraste/loQuieroLeer.png") no-repeat left 30%;
}

.altocontraste #content div.bibliotecaBDO div.obrasLoHeLeido {
    background: transparent url("++resource++afiliados.bdo.resources/altocontraste/loheleidospan.png") no-repeat left 30%;
}

.altocontraste div.eliminarBusqueda {
    background: transparent url("++resource++afiliados.bdo.resources/altocontraste/eliminar_busqueda.png") no-repeat top left;
    padding: 0 0 1em 2em;
}

.altocontraste div.gestionComentarios {
    background-color: black;
}
